export const image1 = require("./1 (1).jpg");
export const image2 = require("./1 (2).jpg");
export const image3 = require("./1 (3).jpg");
export const image4 = require("./1 (4).jpg");
export const image5 = require("./1 (5).jpg");
export const image6 = require("./1 (6).jpg");
export const image7 = require("./1 (7).jpg");
export const image8 = require("./1 (8).jpg");
export const image9 = require("./1 (9).jpg");
export const image10 = require("./1 (10).jpg");
export const image11 = require("./1 (11).jpg");
export const image12 = require("./1 (12).jpg");
export const image13 = require("./1 (13).jpg");
export const image14 = require("./1 (14).jpg");
export const image15 = require("./1 (15).jpg");
export const image16 = require("./1 (16).jpg");
export const image17 = require("./1 (17).jpg");
export const image18 = require("./1 (18).jpg");
export const image19 = require("./1 (19).jpg");
export const image20 = require("./1 (20).jpg");
export const image21 = require("./1 (21).jpg");
export const image22 = require("./1 (22).JPG");
export const image23 = require("./1 (23).jpg");
export const image24 = require("./1 (24).jpg");
export const image25 = require("./1 (25).jpg");
export const image26 = require("./1 (26).jpg");
export const image27 = require("./1 (27).jpg");
export const image28 = require("./r1.jpg");
export const image29 = require("./r2.jpg");
export const image30 = require("./r3.webp");
export const image31 = require("./r4.webp");
export const man = require("./man.jpg");
export const son = require("./son.jpg");
export const c1 = require("./c1.jpg");
export const c2 = require("./c2.jpg");
export const c3 = require("./c3.jpg");
export const c4 = require("./c4.png");
export const c5 = require("./c5.jpg");
export const c6 = require("./c6.png");
export const c7 = require("./c7.jpeg");
export const c8 = require("./c8.png");
export const c9 = require("./c9.jpeg");
export const c10 = require("./c10.jpg");
export const c11 = require("./c11.jpg");
export const re1 = require("./re1.png");
export const re2 = require("./re2.png");
export const re3 = require("./re3.png");
export const re4 = require("./re4.png");
export const re5 = require("./re5.png");
export const r01 = require("./ro1.JPG");
export const r02 = require("./ro2.jpg");
export const r03 = require("./r03.JPG");
export const r04 = require("./ro4.jpg");
export const r05 = require("./ro5.jpg");
export const r06 = require("./ro6.jpg");
export const r07 = require("./ro7.jpg");
export const tu1 = require("./tu1.jpg");
export const tu2 = require("./tu2.jpg");
export const tu3 = require("./tu3.jpg");
export const tu4 = require("./tu4.jpg");
export const tu5 = require("./tu5.jpg");
export const tu6 = require("./tu6.jpg");
export const tu7 = require("./tu7.jpg");
